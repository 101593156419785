/* Hero Section Styles */
.hero-section-cyber {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    color: #1D1D1F;
    padding: 60px 20px;
    background: linear-gradient(115deg, #61b6d2, #5d89c7, #3167d4, #23357e);
    background-size: 400% 400%;
    animation: gradientShift 10s ease-in-out infinite;
  }
  
  @keyframes gradientShift {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .hero-content {
    max-width: 700px;
    margin: 0 auto;
  }
  
  .hero-title {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.2;
    background: linear-gradient(to right, #9bc1ff, #72f0e6);
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .gradient-text2 {
    font-weight: 600;
    background: linear-gradient(to right, #284262, #4444d2);
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .hero-subtitle {
    font-size: 1.4rem;
    margin-bottom: 30px;
    font-weight: 400;
    color: #ffffff;
  }
  
  .cta-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }
  
  .cta-button {
    padding: 14px 28px;
    border: none;
    border-radius: 24px;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.2s ease;
    outline: none;
    color: #fff;
  }

  .cta-button-2{
    padding: 14px 28px;
    border: none;
    border-radius: 24px;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.2s ease;
    outline: none;
    color: #ffffff;
    background-color: #0071E3;


  }
  
  .learn-more {
    background-color: #0071E3;
  }
  
  .learn-more:hover {
    background-color: #005BB5;
    transform: translateY(-2px);
  }
  
  .buy-now {
    background-color: #34C759;
  }
  
  .buy-now:hover {
    background-color: #28A745;
    transform: translateY(-2px);
  }
  
  /* Overview Section Styles */
  .overview-section {
    padding: 60px 20px;
    background-color: #ffffff;
  }
  
  .overview-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    gap: 20px;
  }
  
  .overview-image {
    flex: 1 1 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overview-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 8px;
  }
  
  .overview-text {
    flex: 1 1 50%;
    opacity: 0;
    transform: translateY(20px);
  }
  
  .overview-text.in-view {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .overview-text h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .overview-text p {
    font-size: 1.2rem;
    color: #555;
  }
  
  /* Services Section Styles */
  .services-section {
    padding: 60px 20px;
    background-color: #ffffff;
    text-align: center;
  }
  
  .services-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .services-title {
    font-size: 2.4rem;
    margin-bottom: 40px;
    color: #333;
  }
  
  .services-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .service-card {
    background-color: #f9f9f9;
    border-radius: 12px;
    padding: 20px;
    flex: 1 1 calc(25% - 20px);
    min-width: 250px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .service-card.in-view {
    opacity: 1;
    transform: translateY(0);
  }
  
  .service-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #444;
  }
  
  .service-description {
    font-size: 1rem;
    color: #666;
  }
  
  /* Enhanced Human Risk Management Section Styles */
  .risk-management-section {
    padding: 60px 20px;
    background-color: #ffffff;
  }
  
  .risk-management-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    gap: 20px;
  }
  
  .risk-management-text {
    flex: 1 1 50%;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .risk-management-text.in-view {
    opacity: 1;
    transform: translateY(0);
  }
  
  .risk-management-text h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .risk-management-text p {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 15px;
  }
  
  .risk-management-image {
    flex: 1 1 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .risk-management-image.in-view {
    opacity: 1;
    transform: translateY(0);
  }
  
  .risk-management-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 8px;
  }
  

    
  /* Pricing Section */
.pricing-section {
    text-align: center;
    padding: 60px 20px;
    background: linear-gradient(120deg, #5d89c7, #3167d4, #23357e);
    color: #ffffff;
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }
  
  /* Title styling (using your .pricing-content .section-title) */
  .pricing-content .section-title {
    font-size: 2rem;          /* Adjusted font-size similar to .pricing-title */
    font-weight: 700;         /* Bold for prominence */
    margin-bottom: 10px;      /* Tighter spacing */
    color: #ffffff;
  }
  
  /* Paragraph/Subtitle styling */
  .pricing-content p {
    font-size: 1.2rem;        /* Slightly larger for clarity */
    line-height: 1.6;
    color: #f5f5f5;
    margin-bottom: 30px;
  }
  
  /* Button styling (using your .contact-button) */
  .contact-button {
    background-color: #34C759; /* Green background matching .pricing-button */
    padding: 14px 28px;
    border: none;
    border-radius: 30px;       /* Rounded corners */
    font-size: 1.2rem;
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .contact-button:hover {
    background-color: #28A745;
    transform: translateY(-3px);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .pricing-section {
      padding: 40px 15px;
    }
    .contact-button {
      font-size: 1rem;
      padding: 12px 24px;
    }
  }
  