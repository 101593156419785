/* Apple-inspired system font stack */
.products-page {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #1D1D1F;
  overflow-x: hidden;
  background-color: #F5F5F7;
  margin: 0;
  padding: 0;
}

/* Hero Section */
.hero-section-trustmark {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  color: #1D1D1F;
  padding: 100px 20px;
  background: linear-gradient(115deg, #61b6d2, #5d89c7, #3167d4, #23357e);
  background-size: 400% 400%;
  animation: gradientShift 10s ease-in-out infinite;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hero-content {
  max-width: 700px;
  margin: 0 auto;
}

.hero-title {
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.2;
  background: linear-gradient(to right, #9bc1ff, #72f0e6);
  -webkit-background-clip: text;
  color: transparent;
}

.gradient-text2 {
  font-weight: 600;
  background: linear-gradient(to right, #284262, #4444d2);
  -webkit-background-clip: text;
  color: transparent;
}

.hero-subtitle {
  font-size: 1.4rem;
  margin-bottom: 30px;
  font-weight: 400;
  color: #ffffff;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.cta-button {
  padding: 14px 28px;
  border: none;
  border-radius: 24px;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
  outline: none;
  color: #fff;
}

.learn-more {
  background-color: #0071E3;
}

.learn-more:hover {
  background-color: #005BB5;
  transform: translateY(-2px);
}

.buy-now {
  background-color: #34C759;
}

.buy-now:hover {
  background-color: #28A745;
  transform: translateY(-2px);
}

/* Benefits Section */
.benefits-section {
  background-color: #ffffff;
  padding: 60px 20px;
  text-align: center;
}

.benefits-title {
  color: #1789fa;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.benefits-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1100px;
  margin: 0 auto;
}

.benefit-item {
  width: 30%;
  text-align: center;
  padding: 20px;
}

.benefit-icon {
  font-size: 2.5rem;
  color: #196deb;
  margin-bottom: 10px;
}

.benefit-item h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: #1D1D1F;
}

.benefit-item p {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

/* Responsive Design for Benefits */
@media (max-width: 1024px) {
  .benefit-item {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .benefits-container {
    flex-direction: column;
  }
  .benefit-item {
    width: 100%;
    max-width: 350px;
    margin-bottom: 20px;
  }
}

/* How It Works Section */
.how-it-works {
  background-color: #ffffff;
  padding: 80px 20px;
  display: flex;
  justify-content: center;
}

.how-it-works-container {
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  gap: 40px;
}

.how-it-works-image img {
  max-width: 500px;
  width: 100%;
  height: auto;
}

.how-it-works-text {
  max-width: 600px;
}

.how-it-works-title {
  color: #1f5fff;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.how-it-works-heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #1D1D1F;
}

.how-it-works-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Responsive Design for How It Works */
@media (max-width: 1024px) {
  .how-it-works-container {
    flex-direction: column;
    text-align: center;
  }
  .how-it-works-image img {
    max-width: 100%;
  }
}

/* GIF Section */
.gif-section {
  text-align: center;
  padding: 60px 20px;
  background-color: #ffffff;
}

.gif-title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #1D1D1F;
  margin-bottom: 20px;
}

.gif-container {
  display: flex;
  justify-content: center;
}

.demo-gif {
  max-width: 80%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Responsive for GIF Section */
@media (max-width: 768px) {
  .demo-gif {
    max-width: 100%;
  }
}

/* Capabilities Section (Previously Features Section) */
.capabilities-section {
  padding: 80px 20px;
  background-color: #ffffff;
  text-align: center;
}

.capabilities-title {
  font-size: 2rem;
  font-weight: 700;
  color: #1D1D1F;
  margin-bottom: 15px;
  background: linear-gradient(to right, #5d89c7, #3167d4);
  -webkit-background-clip: text;
  color: transparent;
}

.capabilities-subtitle {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 40px;
  font-weight: 400;
}

.capabilities-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  max-width: 1100px;
  margin: 0 auto;
}

.capability-item {
  width: 30%;
  padding: 30px;
  background: #ffffff;
  border-radius: 14px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.capability-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.capability-icon {
  font-size: 3rem;
  color: #0071E3;
  margin-bottom: 15px;
  transition: transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.capability-item:hover .capability-icon {
  transform: scale(1.1);
}

.capability-item h3 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #1D1D1F;
  margin-bottom: 10px;
}

.capability-item p {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

/* Responsive Design for Capabilities Section */
@media (max-width: 1024px) {
  .capabilities-container {
      flex-direction: row;
      justify-content: center;
  }
  .capability-item {
      width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .capabilities-container {
      flex-direction: column;
      align-items: center;
  }
  .capability-item {
      width: 85%;
      max-width: 400px;
      margin-bottom: 20px;
  }
}

/* Pricing CTA Section */
.pricing-cta {
  text-align: center;
  padding: 60px 20px;
  background: linear-gradient(120deg, #5d89c7, #3167d4, #23357e);
  color: #ffffff;
}

.pricing-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.pricing-subtitle {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 30px;
  color: #f5f5f5;
}

.pricing-button {
  background-color: #34C759;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 14px 28px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.pricing-button:hover {
  background-color: #28A745;
  transform: translateY(-3px);
}

/* Responsive for Pricing CTA Section */
@media (max-width: 768px) {
  .pricing-cta {
    padding: 40px 15px;
  }
  .pricing-button {
    font-size: 1rem;
    padding: 12px 24px;
  }
}
