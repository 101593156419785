.newsletter-section {
  background-color: #f5f5f7;
  padding: 60px 20px;
  min-height: 100vh; /* Full height */
}


.newsletter-container {
  max-width: 500px;
  margin: 60px auto;
  padding: 40px 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: relative;
}

.newsletter-icon {
  margin-bottom: 24px;
}

.newsletter-title {
  font-size: 1.75rem;
  font-weight: 600;
  color: #1f1f1f;
  margin-bottom: 10px;
}

.newsletter-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 30px;
}

/* Horizontal form like in the image */
.newsletter-form {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  max-width: 100%;
  margin: 0 auto;
}

.newsletter-input {
  flex: 1;
  padding: 12px 16px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px 0 0 8px;
  outline: none;
  background-color: #fff;
}

.newsletter-input:focus {
  border-color: #340cb7;
}

.newsletter-button {
  padding: 12px 20px;
  font-size: 1rem;
  background-color: #0b19ad;
  color: #ffffff;
  border: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.newsletter-button:hover {
  background-color: #6d28d9;
}

.newsletter-success {
  margin-top: 20px;
  padding: 12px;
  background-color: #ecfdf5;
  color: #065f46;
  border-radius: 8px;
}

.newsletter-error {
  margin-top: 20px;
  padding: 12px;
  background-color: #fef2f2;
  color: #991b1b;
  border-radius: 8px;
}
