/* WebsiteForm.css */

.website-form-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 30px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .website-form-container h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .website-form-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .website-form-container input[type="url"] {
    padding: 12px 15px;
    font-size: 1rem;
    border: 2px solid #ddd;
    border-radius: 5px;
    transition: border 0.3s;
  }
  
  .website-form-container input[type="url"]:focus {
    border-color: #5c6bc0;
    outline: none;
  }
  
  .website-form-container button {
    padding: 12px 15px;
    font-size: 1rem;
    background: #5c6bc0;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .website-form-container button:hover {
    background: #3949ab;
  }
  
  .website-form-container .error {
    color: #d32f2f;
    font-size: 0.9rem;
    margin-top: 10px;
  }
  