/* Header Container */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F5F5F7;
  color: #000000;
  position: sticky;
  top: 0;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 1000;
}

/* Hamburger Icon */
.hamburger-icon {
  display: none;
  cursor: pointer;
  z-index: 2001;
}

@media (max-width: 768px) {
  .hamburger-icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}

/* Logo */
.logo1 img {
  height: 30px;
  width: auto;
  display: block;
}

/* Navigation */
.nav ul {
  display: flex;
  list-style: none;
  gap: 30px;
  margin: 0;
  padding: 0;
}

.nav ul li a,
.dropdown-toggle {
  color: #6E6E73;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 0;
  transition: color 0.2s ease;
}

.nav ul li a:hover,
.dropdown-toggle:hover {
  color: #000;
}

/* Active Link */
.nav ul li.active a,
.nav ul li.active button {
  color: #0073e6;
  font-weight: 600;
}

/* Dropdown */
.dropdown {
  position: relative;
}

.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  color:#6e6e73;
  font-size: 14px;
  font-family: inherit;
  font-weight: 500;
  padding: 0;
}

/* --- Submenu Styling --- */
.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  background: #ffffff;
  border-radius: 12px;
  padding: 8px 0;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  min-width: 180px;
  border: 1px solid #EBEBEF;
  z-index: 1000;
  display: none;
  margin-top: 8px;
  text-align: center;
}

.dropdown.open .submenu {
  display: block;
}

.submenu li {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #ECEEF0;
}

.submenu li:last-child {
  border-bottom: none;
}

.submenu li a {
  display: block;
  padding: 10px 16px;
  font-size: 14px;
  color: #11181C;
  font-weight: 500;
  transition: background-color 0.2s ease;
  white-space: nowrap;
  text-align: center;
}

.submenu li a:hover {
  background-color: #F5F5F7;
}

/* --- Nested Submenu (Services) --- */
.nested-dropdown {
  position: relative;
}

.nested-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  background: none;
  border: none;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #6e6e73;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-align: center;
}

.nested-toggle:hover {
  background-color: #F5F5F7;
}

.nested-toggle .chevron {
  font-size: 12px;
  color: #6E6E73;
  transition: transform 0.2s ease;
}

.nested-dropdown.open .chevron {
  transform: rotate(90deg);
}

.nested-submenu {
  position: absolute;
  top: -8px;
  left: 100%;
  background: #ffffff;
  border-radius: 12px;
  padding: 8px 0;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  min-width: 180px;
  border: 1px solid #EBEBEF;
  display: none;
  z-index: 1001;
  margin-left: 8px;
  text-align: center;
}

.nested-dropdown.open .nested-submenu {
  display: block;
}

.nested-submenu li {
  border-bottom: 1px solid #ECEEF0;
}

.nested-submenu li:last-child {
  border-bottom: none;
}

.nested-submenu li a {
  display: block;
  padding: 10px 16px;
  font-size: 14px;
  color: #11181C;
  font-weight: 500;
  transition: background-color 0.2s ease;
  text-align: center;
}

.nested-submenu li a:hover {
  background-color: #F5F5F7;
}

/* Mobile Menu */
@media (max-width: 768px) {
  .nav {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    height: 100%;
    flex-direction: column;
    background-color: #ffffff;
    justify-content: flex-start;
    align-items: flex-start;
    transition: right 0.3s ease;
    z-index: 2000;
    padding-top: 80px;
  }

  .nav-open {
    right: 0;
  }

  .nav ul {
    flex-direction: column;
    gap: 0;
    width: 100%;
    padding: 0;
  }

  .nav ul li {
    width: 100%;
    border-bottom: 1px solid #ECEEF0;
  }

  .nav ul li a,
  .dropdown-toggle,
  .nested-toggle {
    padding: 16px 20px;
    display: block;
    width: 100%;
    text-align: left;
  }

  .dropdown .submenu,
  .nested-dropdown .nested-submenu {
    position: static;
    border: none;
    box-shadow: none;
    display: none;
    margin: 0;
    padding: 0;
    width: 100%;
    background: #F5F5F7;
    text-align: left;
  }

  .dropdown.open .submenu,
  .nested-dropdown.open .nested-submenu {
    display: block;
  }

  .nested-submenu {
    left: 0;
    margin-left: 0;
  }

  .nested-toggle .chevron {
    transform: rotate(0deg);
  }

  .nested-dropdown.open .chevron {
    transform: rotate(90deg);
  }

  .submenu li:not(:last-child),
  .nested-submenu li:not(:last-child) {
    border-bottom: 1px solid #E5E5E5;
  }
}

/* Actions */
.actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.try-button {
  background-color: #34c759;
  color: #ffffff;
  border: 1px solid transparent;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 0.3s ease;
  text-decoration: none;
}

.try-button:hover {
  background-color: #30B850;
}

/* Auth Buttons */
.desktop-auth-buttons .login-button,
.desktop-auth-buttons .logout-button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.desktop-auth-buttons .login-button {
  background-color: #007bff;
  color: #ffffff;
}

.desktop-auth-buttons .login-button:hover {
  background-color: #0069D9;
}

.desktop-auth-buttons .logout-button {
  background-color: #dc3545;
  color: #ffffff;
}

.desktop-auth-buttons .logout-button:hover {
  background-color: #C82333;
}

@media (max-width: 768px) {
  .desktop-auth-buttons {
    display: none;
  }

  .actions .try-button {
    margin-right: 10px;
  }
}

/* Mobile Auth Links */
.mobile-auth-link {
  width: 100%;
}

.mobile-auth-link a,
.mobile-auth-link button {
  display: block;
  width: 100%;
  padding: 16px 20px;
  text-align: left;
  text-decoration: none;
}

.mobile-auth-link .logout-button {
  background: none;
  color: #dc3545;
  border: none;
  font-size: 14px;
  font-weight: 500;
}

.mobile-auth-link .logout-button:hover {
  background-color: #F5F5F7;
}

/* Menu Overlay */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
}

.nav-open ~ .menu-overlay {
  opacity: 1;
  visibility: visible;
}

@media (min-width: 769px) {
  .menu-overlay {
    display: none;
  }
}
