/* Global Styles */
body {
    background-color: #ffffff;
  }
  
  /* Hero Section Styles */
  .hero-section-hr {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    color: #1D1D1F;
    padding: 60px 20px;
    background: linear-gradient(115deg, #61b6d2, #5d89c7, #3167d4, #23357e);
    background-size: 400% 400%;
    animation: gradientShift 10s ease-in-out infinite;
  }
  
  @keyframes gradientShift {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .hero-content {
    max-width: 700px;
    margin: 0 auto;
  }
  
  .hero-title {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.2;
    background: linear-gradient(to right, #9bc1ff, #72f0e6);
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .gradient-text2 {
    font-weight: 600;
    background: linear-gradient(to right, #284262, #4444d2);
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .hero-subtitle {
    font-size: 1.4rem;
    margin-bottom: 30px;
    font-weight: 400;
    color: #ffffff;
  }
  
  .cta-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }
  
  .cta-button {
    padding: 14px 28px;
    border: none;
    border-radius: 24px;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.2s ease;
    outline: none;
    color: #fff;
  }
  
  .learn-more {
    background-color: #0071E3;
  }
  
  .learn-more:hover {
    background-color: #005BB5;
    transform: translateY(-2px);
  }
  
  .buy-now {
    background-color: #34C759;
  }
  
  .buy-now:hover {
    background-color: #28A745;
    transform: translateY(-2px);
  }
  
  /* Overview Section Styles */
  .overview-section {
    display: flex;
    align-items: center;
    padding: 40px 20px;
    gap: 40px;
    flex-wrap: wrap;
    background-color: #ffffff;
  }
  
  .overview-image {
    flex: 1;
    min-width: 300px;
  }
  
  .overview-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .overview-text {
    flex: 1;
    min-width: 300px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    color: #555;
  }
  
  .overview-text.in-view {
    opacity: 1;
    transform: translateY(0);
  }
  
  .overview-text h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .overview-text p {
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  /* Our Approach Section (Timeline Layout) */
  .approach-timeline-section {
    background-color: #ffffff;
    padding: 60px 20px;
  }
  
  .approach-timeline-content {
    max-width: 900px;
    margin: 0 auto;
  }
  
  .approach-timeline-content .section-title {
    text-align: center;
    font-size: 2.2rem;
    margin-bottom: 40px;
    color: #333;
  }
  
  .timeline {
    position: relative;
    margin: 0;
    padding: 0;
  }
  
  .timeline::before {
    content: '';
    position: absolute;
    left: 30px;
    top: 0;
    bottom: 0;
    width: 4px;
    background: #ccc;
  }
  
  .timeline-item {
    position: relative;
    padding-left: 70px;
    margin-bottom: 40px;
  }
  
  .timeline-icon {
    position: absolute;
    left: 12px;
    background: #fff;
    border: 4px solid #0071E3;
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
  
  .timeline-content h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #0071E3;
  }
  
  .timeline-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
  }
  
  /* Benefits & Outcomes Section (Image-Inspired Layout) */
  .benefits-wrapper {
    background-color: #ffffff;
  }
  
  .benefits-image-section {
    background-color: #ffffff;
    padding: 40px 20px;
    border-radius: 16px;
    width: 100%;
    
  }
  
  .benefits-image-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
  }
  
  .benefits-illustration {
    flex: 1;
    min-width: 300px;
    text-align: center;
  }
  
  .benefits-illustration img {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 8px;
  }
  
  .benefits-details {
    flex: 1.2;
    min-width: 300px;
  }
  
  .benefits-details h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .benefits-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .benefits-list li {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 1.1rem;
    color: #555;
  }
  
  .benefits-number {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #fff;
    color: #333;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    font-weight: 600;
    margin-right: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Pricing Section */
.pricing-section {
  text-align: center;
  padding: 60px 20px;
  background: linear-gradient(120deg, #5d89c7, #3167d4, #23357e);
  color: #ffffff;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Title styling (using your .pricing-content .section-title) */
.pricing-content .section-title {
  font-size: 2rem;          /* Adjusted font-size similar to .pricing-title */
  font-weight: 700;         /* Bold for prominence */
  margin-bottom: 10px;      /* Tighter spacing */
  color: #ffffff;
}

/* Paragraph/Subtitle styling */
.pricing-content p {
  font-size: 1.2rem;        /* Slightly larger for clarity */
  line-height: 1.6;
  color: #f5f5f5;
  margin-bottom: 30px;
}

/* Button styling (using your .contact-button) */
.contact-button {
  background-color: #34C759; /* Green background matching .pricing-button */
  padding: 14px 28px;
  border: none;
  border-radius: 30px;       /* Rounded corners */
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.contact-button:hover {
  background-color: #28A745;
  transform: translateY(-3px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .pricing-section {
    padding: 40px 15px;
  }
  .contact-button {
    font-size: 1rem;
    padding: 12px 24px;
  }
}
