.hero-section-partner{
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    color: #1D1D1F;
    padding: 100px 20px;
    background: linear-gradient(115deg, #61b6d2, #5d89c7, #3167d4, #23357e);
    background-size: 400% 400%;
    animation: gradientShift 10s ease-in-out infinite;
  }
  
  @keyframes gradientShift {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .hero-content {
    max-width: 700px;
    margin: 0 auto;
  }
  
  .hero-title {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.2;
    background: linear-gradient(to right, #9bc1ff, #72f0e6);
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .gradient-text2 {
    font-weight: 600;
    background: linear-gradient(to right, #284262, #4444d2);
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .hero-subtitle {
    font-size: 1.4rem;
    margin-bottom: 30px;
    font-weight: 400;
    color: #ffffff;
  }
  
  .cta-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }
  
  .cta-button {
    padding: 14px 28px;
    border: none;
    border-radius: 24px;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.2s ease;
    outline: none;
    color: #fff;
  }
  
  .learn-more {
    background-color: #0071E3;
  }
  
  .learn-more:hover {
    background-color: #005BB5;
    transform: translateY(-2px);
  }
  
  .buy-now {
    background-color: #34C759;
  }
  
  .buy-now:hover {
    background-color: #28A745;
    transform: translateY(-2px);
  }
  

  /* Partnership Section */
.partnership-section {
    background-color: #ffffff;
    padding: 60px 20px;
  }
  
  .partnership-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    max-width: 1100px;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  
  .partnership-image img {
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    
    width: 400px;
  }
  
  .partnership-text {
    flex: 1;
    max-width: 550px;
  }
  
  .partnership-text h2 {
    font-size: 2rem;
    color: #196deb;
    margin-bottom: 16px;
  }
  
  .partnership-text p {
    font-size: 1.05rem;
    line-height: 1.7;
    color: #444;
    margin-bottom: 16px;
  }
  
  /* Mobile adjustments */
  @media (max-width: 768px) {
    .partnership-container {
      flex-direction: column;
      text-align: center;
    }
  
    .partnership-text {
      max-width: 100%;
    }
  }
  

  .why-partner-section {
    background-color: #ffffff;
    padding: 80px 20px;
    text-align: center;
  }
  
  .why-partner-title {
    font-size: 2.5rem;
    color: #196deb;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .why-partner-description {
    font-size: 1.1rem;
    color: #444;
    max-width: 800px;
    margin: 0 auto 50px;
    line-height: 1.6;
  }
  
  .why-partner-grid {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
    max-width: 1100px;
    margin: 0 auto;
  }
  
  .partner-benefit {
    flex: 1;
    min-width: 260px;
    max-width: 300px;
    text-align: center;
  }
  
  .partner-icon {
    margin-bottom: 16px;
  }
  
  .partner-benefit h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #196deb;
    margin-bottom: 10px;
  }
  
  .partner-benefit p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .why-partner-grid {
      flex-direction: column;
      align-items: center;
    }
  }

  
  /* Join Ecosystem Section */
.join-ecosystem-section {
    background-color: #ffffff;
    padding: 80px 20px;
    text-align: center;
  }
  
  .ecosystem-content {
    max-width: 900px;
    margin: 0 auto;
  }
  
  .ecosystem-title {
    font-size: 2.5rem;
    color: #196deb;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .ecosystem-description {
    font-size: 1.1rem;
    color: #444;
    line-height: 1.7;
    margin-bottom: 40px;
  }
  
  .ecosystem-button {
    background-color: #196deb;
    color: white;
    border: none;
    font-size: 1rem;
    padding: 14px 28px;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .ecosystem-button:hover {
    background-color: #155cc9;
  }
  