/* General Page Styling */
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f7f9fc;
    color: #333;
    text-align: center;
}

/* Header Section */
.pricing-header {
    padding: 100px 20px;
    color: white;
    text-align: center;
    background: linear-gradient(115deg, #61b6d2, #5d89c7, #3167d4, #23357e);
    background-size: 400% 400%;
    animation: gradientShift 10s ease-in-out infinite;
}

@keyframes gradientShift {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.pricing-header h1 {
    font-size: 2.5rem;
    font-weight: bold;
}

.pricing-header p {
    font-size: 1.2rem;
    font-weight: bold;
}

/* Pricing Section */
.pricing-container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 30px;
    padding: 50px;
    flex-wrap: wrap;
    background-color: #F5F5F7;
}

/* Pricing Cards */
.pricing-card {
    position: relative;
    background-color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    flex: 1 1 300px;
    max-width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    min-height: 490px;
    overflow: hidden;
}

.pricing-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
}

.pricing-card h2 {
    font-size: 1.6rem;
    margin-bottom: 20px;
    color: #333;
}

.price {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.price span {
    font-size: 1rem;
    color: #666;
}

/* Popular Badge */
.popular {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff4500;
    color: white;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.popular .fire-icon {
    margin-right: 5px;
}

/* Features List */
.features {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.features li {
    font-size: 0.8rem;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

/* CTA Buttons */
.btn-pricing {
    background-color: #0071E3;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 25px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-pricing:hover {
    background-color: #005bb5;
    transform: translateY(-2px);
}

/* Highlighting Specific Plans */
.trial {
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.2);
}

.standard {
    box-shadow: 0 0 15px rgba(255, 165, 0, 0.2);
}

.enterprise {
    box-shadow: 0 0 15px rgba(0, 0, 255, 0.2);
    transform: scale(1.05);
}

/* Modal Styles */
.modal-pricing {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content-pricing {
    background: white;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
    width: 100%;
    transition: all 0.3s ease-in-out;
}

.modal-content-pricing h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
}

/* OS Selection */
.os-options-grid {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.os-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.os-option:hover {
    transform: scale(1.1);
}

.os-icon {
    font-size: 3rem;
    margin-bottom: 10px;
}

.close-btn-pricing {
    background-color: red;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.close-btn-pricing:hover {
    background-color: darkred;
}

/* Success Modal */
.modal-success {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.success-modal {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.success-modal h3 {
    font-size: 1.8rem;
    color: #4caf50;
}

.success-modal .btn {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
}

.success-modal .btn:hover {
    background-color: #388e3c;
}

/* Error Modal */
.modal.error-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.error-modal .modal-content {
    background: white;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.error-modal h3 {
    font-size: 1.8rem;
    color: #f44336;
}

.error-modal .btn {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
}

.error-modal .btn:hover {
    background-color: #d32f2f;
}

/* Responsive Design */
@media (max-width: 768px) {
    .pricing-container {
        flex-direction: column;
        padding: 20px;
    }

    .pricing-card {
        max-width: 100%;
        margin-bottom: 20px;
    }
}


.modal-error {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* high z-index to overlay on top of other elements */
  }
  
  .error-modal {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
  